import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loading from './containers/Loading'

const Home = lazy(() => import('./containers/Home'))
const FreeSession = lazy(() => import('./containers/FreeSession'))
const FreeEvent = lazy(() => import('./containers/FreeEvent'))
const OrderGroup = lazy(() => import('./containers/OrderGroup'))
const OrderIndividual = lazy(() => import('./containers/OrderIndividual'))
const ConfirmSubscription = lazy(() => import('./containers/ConfirmSubscription'))
const NotFound = lazy(() => import('./containers/NotFound'))
const Terms = lazy(() => import('./containers/Terms'))
const Rodo = lazy(() => import('./containers/Rodo'))

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path="/bezplatna-konsultacja" component={FreeSession} />
        <Route exact path="/bezplatne-wydarzenie" component={FreeEvent} />
        <Route exact path='/zamowienie/program-grupowy' component={OrderGroup} />
        <Route exact path='/zamowienie/program-indywidualny' component={OrderIndividual} />
        <Route exact path='/potwierdzenie-subskrypcji' component={ConfirmSubscription} />
        <Route exact path='/regulamin' component={Terms} />
        <Route exact path='/rodo' component={Rodo} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}

export default Routes
