import React from 'react'
import './Loading.css'

const Loading = React.forwardRef((props, ref) => (
  <div id='Loading' ref={ref}>
    <div className='row loader'>
      <div className='loader-icon'></div>
    </div>
  </div>
))

export default Loading
