import React, { useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { withRouter } from 'react-router-dom'
import { AppContext } from './libs/contextLib'
import { BreakpointProvider } from './libs/BreakpointContext'
import Routes from './Routes'
import './css/custom.css'
import './App.css'

const queries = {
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)'
}

const App = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  return (
    <AppContext.Provider value={{ scrollPosition, setScrollPosition }}>
      <BreakpointProvider queries={queries}>
        <Routes />
        <CookieConsent
          buttonText="Rozumiem"
          containerClasses="mx-2 mx-sm-5 mx-lg-auto mb-2 mb-sm-5 py-3 px-5 rounded-pill fixed-bottom d-flex justify-content-center align-items-center"
          disableStyles={true}
          style={{ background: "#1b1e2d", color: 'white', maxWidth: '700px' }}
          buttonClasses='btn btn-secondary btn-sm rounded-pill ml-3'
        ><small>Ta strona korzysta z cookie aby świadczyć usługi na najwyższym poziomie.</small></CookieConsent>
      </BreakpointProvider>
    </AppContext.Provider>
  )
}

export default withRouter(App)
